@import "fonts";
@import "bootstrap";

html {
  /*
  ## Base font size for the entire project ##
  All sizes should be defined in REMS whenever possible from now on.
   */
  font-size: 16px;
}

body {

  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.7;
  font-weight: $font-weight-regular;

  //&.footer-bottom {
  //  min-height: 100vh;
  //  display: flex;
  //  flex-direction: column;
  //}
  //overflow-x: hidden;
}

h1, h2, h3, h4 {
  // color: $dark-text;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  margin-bottom: .9em;
}

b, strong {
  font-weight: $font-weight-bold;
}

a {
  text-decoration: none;
  -webkit-transition: color .2s, background-color .2s;
  transition: color .2s, background-color .2s
}

a:focus, a:active {
  outline: 0;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.hand {
  cursor: pointer;
}

.hyphens {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.no-hyphens {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

em {
    font-style: italic;
}

hr {
    border: 0;
    height: 2px;
    background: var(--bs-secondary);
    margin: 60px 0 30px 0;
}

pre, blockquote {
    //margin: 30px 0;
    border: 0;
    border-radius: 0;
    background-color: var(--bs-tertiary-bg);
}

pre code {
  font-size: 0.9em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}


code {
   background-color: var(--bs-tertiary-bg);
    padding: 0 5px;
}

blockquote {
    padding: 15px 20px;
    font-style: italic;
}

pre {
    padding: 20px;
}

/*
NAV BAR
 */
nav.navbar {
  li {
    margin: 0 .5rem;
    a {
      padding-bottom: var(--bs-navbar-brand-padding-y);
    }
  }
  button.navbar-toggler {
    border: 0;
  }
  @media print {
    background-color: #fff !important;
    .navbar-brand, li a, .nav-link {
      color: #000 !important;
    }
  }
}

/*
HOMEPAGE
 */
.container.header {
    text-align: center;
}

.intro-name h1 {
    display: inline-block;
    font-size: 2rem;
    line-height: 1;
    margin-bottom: $heading-reduced-mb;
}

@media (min-width: 768px) {
    .intro-name h1 {
        font-size: 2.8rem;
    }
}

.intro-name h2 {
    font-size: 1.3rem;
}

.container.header ul {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style: none;
}

.container.header ul li {
    display: inline-block;
    margin-right: 30px;
}

.container.header ul li:last-of-type {
    margin-right: 0;
}

img.profile {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: 30px;
}

.typed-cursor {
    opacity: 1;
    animation: blink 1.3s infinite;
    text-align: left;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

ul.name-nav.home {
  line-height: 1;
  li {
    line-height: 1;
  }
}

img.icon.tbcalc {
  width: 1.1rem;
  height: 1.1rem;
  margin-top: -3px;
}

html[data-bs-theme="dark"] img.icon.tbcalc {
  filter: invert(100%);
}

/*
BLOG STYLES
 */

.post.entry {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 2em;
    //&:hover {
    //  cursor: copy;
    //}
    //&::after {
    //  display: none;
    //  margin-left: 0.5rem;
    //  opacity: 0.3;
    //  content: "🔗";
    //}
    //&:hover::after {
    //  display: inline;
    //}
  }
}

.hero, .hero .row {
    height: 490px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
}

.hero .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
}

.hero p, .hero h1, .hero a {
    color: #fff;
    line-height: 1;
    margin-bottom: 10px;
}

.latest-articles {
  h1, h2, h3 {
    margin-bottom: $heading-reduced-mb;
  }
  article.post:first-of-type {
    margin-top: 0 !important;
  }
}

.category-intro {
  top: 3rem;

  ul {
    list-style: none;
    padding-left: 0;
    display: inline;
    > li {
      display: inline;
      &:not(:first-of-type)::before {
        content: "  ·  ";
      }
    }
    &.children > li::before {
      content: "  ·  ";
    }
  }

  @include media-breakpoint-up(lg) {
    ul {
      display: block;
      //padding-left: 2rem;
      ul {
        padding-left: 1rem;
      }
      > li {
        display: list-item;
        &:not(:first-of-type)::before, ::before {
          padding: unset !important;
          content: unset !important;
        }
      }
    }
  }

}

/* Compact Mode Page */
.category-articles {
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
  @include media-breakpoint-up(lg) {
    column-count: 3;
  }
}
.category p {
  margin-bottom: 0;
}
.category:last-of-type {
  border-bottom: 0 !important;
}
/* ----> Compact Mode Page */

.post.nav {
  .page-numbers {
    margin: 0 .5rem;
    &.next, &.prev {
      margin: 0 1.3rem;
      font-weight: $font-weight-medium;
    }
  }
}

.contact-form-section .status {
  p {
    margin-bottom: 0;
    span {
      font-weight: $font-weight-medium;
    }
  }
}



.wp-block-image.size-full {
  text-align: center;
}

table {
  margin-bottom: .85rem;
}

.wp-block-media-text {
  .wp-block-media-text__content {
    padding: 0;
  }
  figure.wp-block-media-text__media {
    max-width: 250px;
    margin: 0 auto 1rem auto;
  }
  @media (min-width: 599px) {
    figure.wp-block-media-text__media {
      max-width: unset;
      margin: 0 1.5rem 1rem 0;
    }
  }
}


.wp-block-table td, .wp-block-table th {
  font-size: 0.9rem;
  padding: 0.5em;
}

.wp-block-image figure:not(.alignfull):not(.alignwide) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.wp-block-file.alignleft {
  float: left;
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.clearfix {
  @include clearfix;
}
.clearfix-inline {
  clear: both;
}

//.wp-block-buttons {
//  a {
//    margin: 0.5rem auto 2rem auto;
//    padding: .5rem 1rem;
//    color: #fff;
//    text-transform: uppercase;
//    border: unset;
//    border-radius: 0 !important;
//  }
//  .has-ptcook-primary-background-color {
//    background-color: $callout-evora;
//  }
//  .has-ptcook-lisbon-background-color {
//    background-color: $callout-lisbon;
//  }
//  @include media-breakpoint-up(sm) {
//    a::after {
//      color: #fff;
//      font-family: 'Font Awesome 5 Pro';
//      content: "\f178";
//      speak: none;
//      display: block;
//      font-weight: 400;
//      float: right;
//      padding-left: .5rem;
//    }
//  }
//  @media print {
//    display: none !important;
//  }
//}
//

@media print {
  html {
    font-size: 14px;
  }
  body {
    color: #000 !important;
    background-color: #fff !important;

  }
  .container, .row, [class*='col-'] {
    width:100% !important;
  }
  pre, blockquote, code {
    padding: unset !important;
    background-color: unset !important;
  }
}

/* XMAS Effects */
html[data-xmas="true"] {
  .header.intro-name h1::before {
    content: "";
    display: block;
    position: absolute;
    width: 50px;
    height: 55px;
    background-image: url("../resources/images/santa-hat.png");
    background-repeat: no-repeat;
    margin-left: -20px;
    margin-top: -15px;
    z-index: 1000;
    transform: scaleX(-1);
  }
  .navbar-nav li.toggle-snow {
    display: block !important;
  }
  .snowflakes div  {
    color:#fff;font-size:.6em;font-family:Arial sans-serif;text-shadow: 0 0 1px #000;
  }
  @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflakes div{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflakes div:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflakes div:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflakes div:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflakes div:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflakes div:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflakes div:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflakes div:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflakes div:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflakes div:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflakes div:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
}
