/* Theme Variables */
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

$small-text-size: 0.8rem;
$line-height-base: 1.75;

/* Bootstrap Variables Overrides */
//$primary: #15a6f0;
$body-color-dark: #f0f0f0;
$body-bg-dark: #17212B;
$body-tertiary-bg-dark: #202B36;

// #202B36

$grid-gutter-width: 2.5rem;
//$input-border-color: $light-border;
// $input-focus-border-color: $primary;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
// $card-border-color: $light-border;
$form-label-margin-bottom: 0.2rem;
$form-label-font-size: .885rem;
$input-line-height: 2.2;

$border-width:                 2px;
$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-2xl:           2rem !default;
$border-radius-pill:          50rem !default;

// $form-check-input-border:   2px solid $input-border-color;
$form-check-input-width:    1.2em;

/* Headings Config */
$lead-font-size: 0.5rem;
$headings-font-weight: $font-weight-medium;
$font-size-base-headings: .7rem;
$h1-font-size: $font-size-base-headings * 2.5 !default;
$h2-font-size: $font-size-base-headings * 2 !default;
$h3-font-size: $font-size-base-headings * 1.75 !default;
$h4-font-size: $font-size-base-headings * 1.5 !default;
$h5-font-size: $font-size-base-headings * 1.25 !default;
$h6-font-size: $font-size-base-headings !default;

$heading-reduced-mb: .5rem;
